/**
Author - Brijesh Pandey
Git - https://bitbucket.org/__brijesh/
**/

import React, {
  useEffect, 
  useState
} from "react";
import PropTypes from "prop-types";

import {
  getCvlStatus,
  getDigilockerData,
  getSpecimenSign,
  getEsignRequest,
  getDdpiRequest,
  getSelfieRequest,
  checkPanAadharSeeded,
  uploadPanCard
} from "api";

import {
  handleApiError
} from "helpers";

import InitiateApiCalls from "./InitiateApiCalls";
import useMSClarity from "hooks/useMSClarity";

const propTypes = {
  kycData: PropTypes.object.isRequired,
  updateStatus: PropTypes.func.isRequired,
  handleErrorState: PropTypes.func
};

const InitiateApiCallsContainer = ({
  kycData,
  updateStatus,
  handleErrorState
}) => {
  const [isError, setIsError] = useState(false);
  const [digioDetails, setDigioDetails] = useState("");

  const {sendClarityEvent, setClarityCustomTag} =
    useMSClarity();


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = 'Reload is nor recommended unless if you face any error';
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  useEffect(() => {
    setClarityCustomTag(
      'kyc_id',
      kycData?.id
    );
    sendClarityEvent(`STEP_INIT_API_CALL_${kycData.stage}_${kycData.id}`);

    switch (kycData.stage) {
      case "CVL":
        handleApiCall(getCvlStatus);
        break;

      case "DIGILOCKER":
        handleApiCall(getDigilockerData);
        break;

      case "SPECIMEN_SIGN":
        handleApiCall(getSpecimenSign);
        break;

      case "SELFIE":
        handleApiCall(getSelfieRequest);
        break;

      case "E_SIGN":
        handleApiCall(getEsignRequest);
        break;

      case "DDPI":
        handleApiCall(getDdpiRequest);
        break;

      case "PAN_ANALYSIS":
        handleApiCall(checkPanAadharSeeded);
        break;

      case "UPLOAD_PAN":
        handleApiCall(uploadPanCard);
        break;

      default:
        break;
    }
  }, []);

  const handleApiCall = async (correspondingCall) => {
    try {
      const response = await correspondingCall();
      const result = response.data;
      const digioDetailsPresent = !!result.digioDetails;
      if (digioDetailsPresent) {
        setDigioDetails(result.digioDetails);
      } else {
        updateStatus();
      }
    } catch (error) {
      handleApiError(error);
      setIsError(true);
    } finally {
    }
  };

  return (
    <InitiateApiCalls
      digioDetails={digioDetails}
      isError={isError}
      setDigioDetails={setDigioDetails}
      kycData={kycData}
      updateStatus={updateStatus}
      handleErrorState={handleErrorState}
    />
  );
};

InitiateApiCallsContainer.propTypes = propTypes;

export default InitiateApiCallsContainer;
