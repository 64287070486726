/**
Author - Brijesh Pandey
Git - https://github.com/Brijesh-Pandey
**/

import React, {
useState,
useEffect
} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import ReactAudioPlayer from 'react-audio-player';

import {
  ActionButton, Toggler
} from "screens/commons";

import {
  KycScreenMapping,
  KycFlowMapping
} from "types";
import IMAGES from "constants/Images";
import useMSClarity from "hooks/useMSClarity";

const englishAudio = "https://i.wlycdn.com/wealthy-kyc/digilocker-english-raveena-audio.mp3";
const hindiAudio = "https://i.wlycdn.com/wealthy-kyc/digilocker-hindi-audio.mp3";

const possibleLanguages = [{
  id: 0,
  langauge: "english",
  source: englishAudio,
  displayText: "हिंदी"
}, {
  id: 1,
  langauge: "hindi",
  source: hindiAudio,
  displayText: "English"
}];

const propTypes = {
  handleDigioInitialization: PropTypes.func.isRequired,
  kycData: PropTypes.object.isRequired
};

const ClientKyc = ({
  handleDigioInitialization,
  handleGoBack,
  kycData
}) => {
  const [flipAudio, setFlipAudio] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState(possibleLanguages[0]);

  const {sendClarityEvent} =
    useMSClarity();

  useEffect(() => {
    if (activeLanguage.id === 1) {
      setActiveLanguage(possibleLanguages[0]);
    } else {
      setActiveLanguage(possibleLanguages[1]);
    }
  }, [flipAudio]);

  const {stage,flow} = kycData;
  const stageText = stage.split("_").join(" ");
  const showDetailedDescription = !! (stage === KycScreenMapping.DIGILOCKER || stage === KycScreenMapping.E_SIGN);

  const specimenSign = "https://i.wlycdn.com/wealthy-kyc/digital-signature";
  const digiLocker = "https://i.wlycdn.com/wealthy-kyc/digi-locker.svg";
  const selfieUpload = "https://i.wlycdn.com/wealthy-kyc/selfie-upload.svg";
  const mandateImg = "https://i.wlycdn.com/icons/emandate-success.png";

  let title = `Next step is ${stageText} KYC`;
  let subTitle = "Thank you for your patience, please click on the 'NEXT' button to move forward";
  let description = "";
  let imgPath = "";
  let actionButtonText = "Continue";
  let goBackButtonText = "Process Manually";

  switch (stage) {
    case KycScreenMapping.DIGILOCKER:
      title = "DigiLocker Verification";
      subTitle = "";
      description = "";
      imgPath = digiLocker;
      break;
    case KycScreenMapping.SELFIE:
      title = "Next step Selfie";
      subTitle = "Upload Selfie";
      description = "Please click on the 'CAPTURE SELFIE' button whenever you are ready to move forward.";
      actionButtonText = "CAPTURE SELFIE";
      imgPath = selfieUpload;
      break;
    case KycScreenMapping.SPECIMEN_SIGN:
      title = "Next step is Specimen Signature";
      subTitle = "Specimen Signature";
      description = "Please provide your signature for verification purposes, click on 'SIGN' button to move forward.";
      actionButtonText = "SIGN";
      imgPath = specimenSign;
      break;
    case KycScreenMapping.E_SIGN:
      title = "Next step is Electronic Signature";
      subTitle = "Electronic Signature";
      description = "Please provide your electronic signature for verification purposes, click on 'E SIGN' button to move forward.";
      actionButtonText = "E SIGN";
      imgPath = specimenSign;
      break;
    case KycScreenMapping.DDPI:
      title = "Current step is Demat Debit & Pledge Instructions (DDPI)";
      subTitle = "via E-Sign";
      description = "Please provide your e-signature for verification purposes, click on 'Proceed' button to move forward.";
      actionButtonText = "Continue to DDPI";
      imgPath = mandateImg;
      break;
    default:
      title = `Next step is ${stageText} KYC`;
      subTitle = "Thank you for your patience, please click on the 'NEXT' button to move forward";
      description = "";
      imgPath = "";
      break;
  }

  return (
    <Container>
      <KycWrapper>
        <Title>{title}</Title>
        <Wrapper>
          {
            showDetailedDescription ?
              (
                <DigiLockerImage>
                  <img
                    alt={title}
                    className="kyc-image"
                    src={imgPath}
                  />
                </DigiLockerImage>
              ) : (
                <KycImage>
                  <img
                    alt={title}
                    className="kyc-image"
                    src={imgPath}
                  />
                </KycImage>
              )}

              {
                stage === KycScreenMapping.DIGILOCKER ? (
                  <HelpContainer>
                    <ReactAudioPlayer
                      src={activeLanguage.source}
                      autoPlay
                      controls
                    />
                    <Toggler
                      values={["हिंदी", "English"]}
                      switchToggle={setFlipAudio}
                      toggle={flipAudio}
                    />
                  </HelpContainer>
                ) : null
              }

          <Subtitle>{subTitle}</Subtitle>
          {
            showDetailedDescription ?
              (
                <NoteContainer>
                  <PointWrapper>
                    <BulletImage
                      alt="bullet-image"
                      src="https://i.wlycdn.com/wealthy-home-page/bullet-star.svg"
                      loading="lazy"
                    />
                    <NoteDescription>
                      Keep your Aadhar number and mobile
                      number linked with Aadhar handy.
                    </NoteDescription>
                  </PointWrapper>

                  <PointWrapper>
                    <BulletImage
                      alt="bullet-image"
                      src="https://i.wlycdn.com/wealthy-home-page/bullet-star.svg"
                      loading="lazy"
                    />
                    <NoteDescription>
                      You will receive OTP on this number.
                    </NoteDescription>
                  </PointWrapper>

                  {
                    stage === KycScreenMapping.DIGILOCKER ? (
                        <>
                          <PointWrapper>
                            <BulletImage
                              alt="bullet-image"
                              src="https://i.wlycdn.com/wealthy-home-page/bullet-star.svg"
                              loading="lazy"
                            />
                            <NoteDescription>
                              If you haven't set up your DigiLocker PIN,
                              or if you've forgotten it, click on "Forgot
                              security PIN" on the DigiLocker KYC screen.
                            </NoteDescription>
                          </PointWrapper>

                          <PointWrapper>
                            <BulletImage
                              alt="bullet-image"
                              src="https://i.wlycdn.com/wealthy-home-page/bullet-star.svg"
                              loading="lazy"
                            />
                            <NoteDescription>
                            In case your PAN is not fetched from digilocker, use your name as mentioned in ITR
                            </NoteDescription>
                          </PointWrapper>
                        </>
                    ) : null
                  }

                </NoteContainer>
              ) : (
                <Description>{description}</Description>
              )}
        </Wrapper>
        <ActionButton
          buttonText={actionButtonText}
          callback={() => {
            handleDigioInitialization();
            sendClarityEvent(`Init_DIGIO_SDK_${stage}_${kycData.id}`);
          }}
          className="next-button"
        />
        {/* Only show this button for MF_NRI flow and on digilocker stage */}
        {(stage === KycScreenMapping.DIGILOCKER && flow === KycFlowMapping.MF_NRI) && (
          <>
            <ActionButton
              buttonText={goBackButtonText}
              callback={() => handleGoBack(flow)}
              additionalStyles={{
                background: 'gray'
              }}
              />
            <span>Incase of failure or face any issues with digilocker there is always options for upload your id proofs manually. 
            &nbsp;<img src={IMAGES.infoIconThemeColor} alt="info-icon" title="Choose Manual option after clicking this button" />
            </span>
          </>
        )}
      </KycWrapper>
    </Container>
  );
};

const HelpContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem 0;
`;

const Container = styled.div`
  padding: 2.4rem 0;
  margin: 0 auto;
  width: 90%;
  position: relative;
  overflow:auto;
  flex-grow:1;

  @media(max-width: 768px) {
    height: 100%;
    margin-bottom:2rem;
    .next-button{
      position: fixed;  
      bottom:1.5rem;
      left:50%;
      transform:translateX(-50%);
      width: 90%;
    }
  }
`;

const PointWrapper = styled.div`
  display: flex;
`;

const Button = styled.button`
  outline: none;
  background: transparent;
  margin: 1.5rem 0;
`;

const BulletImage = styled.img`
  margin-right: 1rem;
  height: 100%;
  margin-top: 2.1rem;
`;

const KycWrapper = styled.div`
  height: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 6rem 0 0;

  @media screen and (max-width: 768px) {
     margin: 2rem 0 0;
  }
`;

const Title = styled.h1.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
  align-self: start;

  @media screen and (max-width: 768px) {
    font-size:16px;

  }

`;

const Subtitle = styled.p.attrs({
  className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  color: #000000;
  letter-spacing: 0.1rem;
`;

const Description = styled.span.attrs({
  className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
  width: 80%;
  text-align: center;
`;

const NoteContainer = styled.div`
  background-color: #F9F9F9;
  border-radius: 0.375rem;
  padding: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 4rem;
`;

const NoteDescription = styled.div.attrs({
  className: 'description2'
})`
  color: #1C1C1C;
  margin: 1.4rem 0 0 0;
`;

const KycImage = styled.div`
  align-self: center;
  margin-bottom: 4rem;
  background-color: #ECF0FC;
  border-radius: 0.75rem;
  height: 25rem;
  width: 28rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3.125rem 0rem;

  .kyc-image {
    height: 100%;
    width: 100%;
  }
`;

const DigiLockerImage = styled.div`
  align-self: center;
  margin-bottom: 4rem;

  .kyc-image {
    width: 15rem;
  }

  @media (max-width: 768px) {
    width: 10rem;
    .kyc-image {
      width: 8rem;
    }
  }
`;

ClientKyc.propTypes = propTypes;

export default ClientKyc;