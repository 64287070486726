/**
Author - Venkatesh 😁
Git - https://github.com/venkatesh1146
**/


import React, {
    useEffect
} from "react";

import styled from "styled-components";

import MetaForm from "@manojadams/metaforms";

const FATCAForm = ({
    formSchema,
    handleFormSubmit
}) => {
    useEffect(() => {
        const stepperContainer = document.querySelector('[class*="meta-form-stepper"]');

        if (!!stepperContainer) {
            stepperContainer.classList.add("stepper");
        }
    }, []);

    return (
        <Container>
            <Wrapper>
                <Title>{formSchema?.pageTitle ? formSchema.pageTitle  : "FATCA Details"}</Title>
                <SubHeader className="mb-5">{formSchema?.cardTitle ? formSchema.cardTitle : "Please fill in your FATCA details"}</SubHeader>
                <FormWrapper>
                    <MetaForm
                        schema={formSchema?.schema}
                        onSubmit={(data) => {
                            handleFormSubmit(data);
                        }}
                    />
                </FormWrapper>
                <ExtraSpace />
            </Wrapper>
        </Container>
    );
};

const Container = styled.div`
  height: 100vh;
  width: 100%;
  scroll-behavior: scroll;
  position: relative;
  overflow-y: scroll;
  display:flex;
  flex-direction:column;



  @media (max-width: 768px) {
    background: white;
  }
  .container, .basic-form{
    height:100%;
  }
  form > div {
    height:100%;
    .row {
        height: 100%;
    }
  }
  .footer {
    margin-top: 4rem;
  }
`;

const Wrapper = styled.div`
  padding: 2.4rem 0;
  width: 90%;
  margin: 0 auto;
  flex-grow:1;
  display:flex;
  flex-direction:column;
`;

const ExtraSpace = styled.div`

  height: 250px;
  margin: 10rem 0;
  opacity: 0;
  @media screen and (max-width: 768px) {
    height:0;
  }
`;


const Title = styled.h1.attrs({
    className: 'primary-title'
})`
  margin: 0;
  padding: 0;
  letter-spacing: 0.1rem;
`;

const SubHeader = styled.p.attrs({
    className: 'description2'
})`
  margin: 1rem 0 0 0;
  padding: 0;
  color: #7E7E7E;
`;

const FormWrapper = styled.div`
    flex-grow:1;
  .stepper {
    margin: 2rem;
    opacity: 0;
  }

  .input-container {
    margin-bottom: 0.8rem;
  }

  .input-container label {
    font-size: 1.4rem;
    font-weight: 400;
    color: #7E7E7E;
  }

  .input-container label+div {
    font-size: 1.6rem;
    font-weight: 400;
    color: #000000;
  }

  .input-container input {
    border: none;
    background: transparent;
    border-bottom: 1px solid #DDDDDD;
    outline: none;
    padding-bottom: 0.5rem;
    color: #000000;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    width: 100%;
    text-transform: uppercase;
  }

  .action-btn {
    padding: 1rem 0;
    background: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    color: #ffffff;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: scale 300ms;
    margin-top:auto;

    :hover {
      background-color: #6725F4;
      scale: 1.02;
    }
  }

  .prev-action-btn {
    padding: 2rem 0;
    background: transparent;
    color: #6725F4;
    border-radius: 52px;
    width: 100%;
    margin-top: 3rem;
    font-size: 1.6rem;
    font-family: MavenPro;
    font-weight: 500;
    display: flex;
    justify-content: left;
    align-items: center;

    :hover {
      background: transparent;
    }
  }
`;

export default FATCAForm;
